
import loadable from '@loadable/component';
import NotFound from './pages/NotFound';

// Main


// Admin
const Admin = loadable(() => import("./pages/Admin"), { ssr: true });
const AdminLogin = loadable(() => import("./pages/misc/AdminLogin"), { ssr: true });


const AdminTemplateEdit = loadable(() => import("./pages/templates/AdminTemplateEdit"), { ssr: true });
const AdminTemplateNew = loadable(() => import("./pages/templates/AdminTemplateNew"), { ssr: true });
const AdminTemplates = loadable(() => import("./pages/templates/AdminTemplates"), { ssr: true });


const AdminSponsorEdit = loadable(() => import("./pages/sponsors/AdminSponsorEdit"), { ssr: true });
const AdminSponsorNew = loadable(() => import("./pages/sponsors/AdminSponsorNew"), { ssr: true });
const AdminSponsors = loadable(() => import("./pages/sponsors/AdminSponsors"), { ssr: true });


const AdminJobEdit = loadable(() => import("./pages/jobs/AdminJobEdit"), { ssr: true });
const AdminJobNew = loadable(() => import("./pages/jobs/AdminJobNew"), { ssr: true });
const AdminJobs = loadable(() => import("./pages/jobs/AdminJobs"), { ssr: true });


const AdminCountryEdit = loadable(() => import("./pages/countries/AdminCountryEdit"), { ssr: true });
const AdminCountryNew = loadable(() => import("./pages/countries/AdminCountryNew"), { ssr: true });
const AdminCountries = loadable(() => import("./pages/countries/AdminCountries"), { ssr: true });



const AdminStateEdit = loadable(() => import("./pages/states/AdminStateEdit"), { ssr: true });
const AdminStateNew = loadable(() => import("./pages/states/AdminStateNew"), { ssr: true });
const AdminStates = loadable(() => import("./pages/states/AdminStates"), { ssr: true });


const AdminCompanyEdit = loadable(() => import("./pages/companies/AdminCompanyEdit"), { ssr: true });
const AdminCompanyNew = loadable(() => import("./pages/companies/AdminCompanyNew"), { ssr: true });
const AdminCompanies = loadable(() => import("./pages/companies/AdminCompanies"), { ssr: true });


const AdminArticleEdit = loadable(() => import("./pages/articles/AdminArticleEdit"), { ssr: true });
const AdminArticleNew = loadable(() => import("./pages/articles/AdminArticleNew"), { ssr: true });
const AdminArticles = loadable(() => import("./pages/articles/AdminArticle"), { ssr: true });


const AdminAdvertEdit = loadable(() => import("./pages/adverts/AdminAdvertEdit"), { ssr: true });
const AdminAdvertNew = loadable(() => import("./pages/adverts/AdminAdvertNew"), { ssr: true });
const AdminAdverts = loadable(() => import("./pages/adverts/AdminAdverts"), { ssr: true });




export const Routes = [


  // Admin (article)
  {
    path: '/article/edit/:id',
    component: AdminArticleEdit
  },
  {
    path: '/article/new',
    component: AdminArticleNew
  },
  {
    path: '/articles',
    component: AdminArticles
  },

  // Admin (companies)
  {
    path: '/company/edit/:id',
    component: AdminCompanyEdit
  },
  {
    path: '/company/new',
    component: AdminCompanyNew
  },
  {
    path: '/companies',
    component: AdminCompanies
  },

  // Admin (countries)
  {
    path: '/country/edit/:id',
    component: AdminCountryEdit
  },
  {
    path: '/country/new',
    component: AdminCountryNew
  },
  {
    path: '/countries',
    component: AdminCountries
  },

  // Admin (states)
  {
    path: '/state/edit/:id',
    component: AdminStateEdit
  },
  {
    path: '/state/new',
    component: AdminStateNew
  },
  {
    path: '/states',
    component: AdminStates
  },


  // Admin (sponsor)
  {
    path: '/sponsor/edit/:id',
    component: AdminSponsorEdit
  },
  {
    path: '/sponsor/new',
    component: AdminSponsorNew
  },
  {
    path: '/sponsors',
    component: AdminSponsors
  },

  // Admin (adverts)
  {
    path: '/advert/edit/:id',
    component: AdminAdvertEdit
  },
  {
    path: '/advert/new',
    component: AdminAdvertNew
  },
  {
    path: '/adverts',
    component: AdminAdverts
  },

  // Admin (applications)
  {
    path: '/templates',
    component: AdminTemplates
  },
  {
    path: '/template/edit/:id',
    component: AdminTemplateEdit
  },
  {
    path: '/template/new',
    component: AdminTemplateNew
  },

  // Admin (jobs)
  {
    path: '/job/edit/:id',
    component: AdminJobEdit
  },
  {
    path: '/job/new',
    component: AdminJobNew
  },
  {
    path: '/jobs',
    component: AdminJobs
  },


  {
    path: '',
    component: AdminLogin
  },



  {
    path: '/not-found',
    component: NotFound
  },

  {
    path: '',
    exact: true,
    component: Admin
  },

  {
    component: NotFound
  },



];