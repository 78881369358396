import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';

import reducer from './store/reducer';

const configureStore = (initialState = null) => {
  let middleware = [
    thunk,
  ];

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
  }

  var store = null;
  
  if(initialState){
    store = createStore(reducer, initialState, applyMiddleware(...middleware));
  } else{
    store = createStore(reducer, applyMiddleware(...middleware));
  }



  return store;
};


export default configureStore;
