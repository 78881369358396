import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

class NotFound extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {

		var metaTitle = "Page not found · 4 Day Week Jobs";
		var metaDesc = "Well this is awkward, this page doesn't exist. Maybe you mistyped the URL or the page has been moved?";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/not-found";

		return (
			<div className="main-container medium-width not-found">
				<Helmet>
					<title>{metaTitle}</title>

					<meta name="title" content={metaTitle} />
					<meta name="description" content={metaDesc} />

					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImg} /><meta property="og:type" content="website" />
					<meta property="og:url" content={metaUrl} />

					<meta property="twitter:card" content="summary_large_image" />
					<meta property="twitter:url" content={"@4dayweekio"} />
					<meta property="twitter:title" content={metaTitle} />
					<meta property="twitter:description" content={metaDesc} />
					<meta property="twitter:image" content={metaImg} />
					<link rel="canonical" href={metaUrl} />
				</Helmet>


				<div className="not-found">

			
					<section className="row hero section-header">
						<div className="col-sm-8 hero-left">
							<h1>404 - Sorry, page not found</h1>
							<h3>{metaDesc}</h3>
							<Link to="/" className="btn btn-success cta btn-huge margin-top">Go to homepage</Link>
						</div>

						<div className="col-sm-4 hero-right hide-mobile ">
							<img src={`/assets/404.svg`} className="hero-img negative-margin-top-small" alt="Page not found" height={362} />
						</div>
					</section>

				</div>
			</div >
		);
	}
}

export default NotFound;