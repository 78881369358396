import { combineReducers } from 'redux';
import item from './item/reducer';
import list from './list/reducer';
import admin from './admin/reducer';

export default combineReducers({
    item: item,
    admin: admin,
    list: list,
});
