import * as URL from '../../utils/url';
import {
  apiURL
} from '../vars';
import axios from 'axios';


export const REQUEST_LIST_ITEMS = 'REQUEST_LIST_ITEMS';
export const RECEIVE_LIST_ITEMS = 'RECEIVE_LIST_ITEMS';
export const RESET_LIST_ITEMS = 'RESET_LIST_ITEMS';

const requestItems = (page, id, params) => ({
  type: REQUEST_LIST_ITEMS,
  params: params,
  page: page,
  id: id,
});


const receiveItems = (page, id, response, params) => ({
  type: RECEIVE_LIST_ITEMS,
  page: page,
  id: id,
  params: params,
  response: response
});


export const resetItems = (page, id) => ({
  type: RESET_LIST_ITEMS,
  page: page,
  id: id,
});

export const fetchItems = (page, id, params = {}) => {
  var newReq = {
    "page": page,
    "id": id,
    "params": params,
    "type": RECEIVE_LIST_ITEMS
  }

  return (dispatch, getState) => {


    var lastReq = getState().list;
    
    if(lastReq[page+"_"+id]){
      lastReq = lastReq[page+"_"+id].lastRequest;
    }


    var options = {
      method: "GET",
      //   credentials: 'same-origin',
    }

    var urlParams = URL.AsParams(params)
    var req = `/data/` + page + `/` + id + `.json?${urlParams}`

    dispatch(requestItems(page, id, params));



    return fetch(req, options).then(function (response) {
      return response.json();
    }).then(function (json) {

      dispatch(receiveItems(page, id, json, params));
    });
  };
};

export const fetchItemsSSR = (page, id, params = {}) => {
  var options = {
    method: "GET",
  }

  var urlParams = URL.AsParams(params)
  var req = apiURL + page + `/` + id + `.json?${urlParams}`


  var options = {
    method: "GET",
  }

  var ret = axios.get(req, options).then(response => {
    return response.data
  });
  return ret;
};