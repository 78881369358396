import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as adminActions from '../../store/admin/actions';
import logoImg from '../../assets/logo-2023.svg';


class Menu extends React.Component {

	constructor() {
		super();
		this.state = {
			collapsed: false,
		};
	}

	getLogout() {
		console.log("Logging out...")
		this.props.getLogout();
		this.props.history.push("/");
	}

	onToggle(){
		this.setState({collapsed: !this.state.collapsed});
	}


	render() {
		var { admin } = this.props;
		var { collapsed } = this.state;

		var path = this.props.location.pathname.trim()
		var spl = path.split("/");

		var sub = "";
		if (spl.length > 1) {
			sub += spl[1];
		}

		var adminData = admin.login.response.data;

		var isLoggedIn = admin.login.requested && !admin.login.response.error;

		if (!isLoggedIn) {
			return "";
		}
		var pages = ["jobs", "companies", "countries", "sponsors", "articles", "adverts", "templates"];

		var pagesList = pages.map((d) => adminData.pages && adminData.pages.indexOf(d) > -1 ? <li><Link to={"/" + d} className={"btn btn-ghost btn-lg" + (sub == d  ? ' active' : "")}>{d}</Link></li> : "")

		pagesList.push(<li><a href="#" onClick={() => this.getLogout()} className={"btn btn-ghost btn-lg"}>Logout</a></li>)

		return (

			<div className="row header-container admin-nav">

				<nav className="navbar">

					<div className="container-fluid">

						<div className="navbar-header">
							<Link to="/" className="navbar-brand">

								<LazyLoadImage
									threshold={100}
									width={180}
									height={40}
									src={logoImg}
									className="logo"
									alt="4dayweek logo"
								/>

							</Link>
							<button type="button" className="navbar-toggle collapsed"  aria-label="toggle"  data-toggle="collapse" data-target="#main-menu" aria-expanded="false" onClick={() => this.onToggle()}>
								<span className="sr-only"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>

						</div>

						<div className={(collapsed ? "collapse" : "") + " navbar-collapse"} id="main-menu">

							{/* Mobile */}
							<ul className="nav navbar-nav">
		
								{pagesList}
							</ul>

						</div>
					</div>
				</nav>

			</div >

		);
	}
}

const mapStateToProps = (state) => {
	return {
		admin: state.admin,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getLogout: () => {
		dispatch(adminActions.getLogout());
	},
});


Menu = connect(mapStateToProps, mapDispatchToProps)(Menu);

export default withRouter(Menu);






