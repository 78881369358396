import * as actions from './actions';

/*
Used to create and test admins
*/

export function getDefaultAdminState() {

	return {
		login: {
			requested: false,
			loading: false,
			response: {}
		},
	}

};


const admin = (state, action) => {
	var temp = Object.assign({}, state);

	switch (action.type) {

		// Get
		case actions.GET_LOGIN_REQUEST:
			temp.login = {
				requested: false,
				loading: true,
				response: {}
			};
			return temp;

		case actions.GET_LOGIN_RESPONSE:
			temp.login = {
				requested: true,
				loading: false,
				response: action.response
			};
			return temp;

		case actions.GET_LOGOUT_REQUEST:
			temp.login = {
				requested: false,
				loading: true,
				response: {}
			};
			return temp;

		case actions.RESET_ADMIN_REQUEST:
			return getDefaultAdminState();

		default:
			return state ? state : getDefaultAdminState();
	}
};

export default admin;