
// Meh

import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
//import CookieConsent from 'react-cookie-consent-notification';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Menu from './components/global/Menu';
import ScrollToTop from './components/misc/ScrollToTop';
import { Routes } from './routes';
import * as adminActions from './store/admin/actions';


class App extends React.Component {


	constructor(props) {
		super(props);

		this.handleScroll = this.handleScroll.bind(this)
		this.state = {
		};
	}


	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true, this);

		this.props.getLogin();

	}

	componentDidUpdate(prevProps) {

		var { login } = this.props;
		var path = this.props.location.pathname.trim()

		if ((path == "/" || path == "/login")) {
			if (login.response && !login.response.error && login.response.data && (path == "/" || path == "/login")) {

				this.props.history.push("/" + login.response.data.pages[0]);
			}
		} else {
			if (login.response && login.response.error) {
				this.props.history.push("/");
			}
		}

	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		var { scrolled } = this.state;

		const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		if ((!scrolled && winScroll > 100) || (scrolled && winScroll <= 10)) {
			this.setState({ scrolled: !scrolled });
		}

	}


	render() {

		let { filters } = this.props;

		let { scrolled } = this.state;

		if (filters && (!filters.init || filters.loading)) {
			return "";
		}

		return <>
			<div className={"wrapper "} id="outer-wrapper">
				{/*<CookieConsent bottomPosition={true} buttonText={'I agree'}> Please confirm your consent to the use of cookies. See our <Link to={'/privacy'} style={{ color: '#fff' }}>privacy</Link> and <Link to={'/cookies'} style={{ color: '#fff' }}>cookie</Link> policies.</CookieConsent>*/}
				<div className={"content-wrapper full-width " + (scrolled ? "scrolled" : "")} id="content-wrapper">
					<Menu />
					<div className="main-container-wrapper" name="">

						<ScrollToTop>
							<Switch>
								{Routes.map((r) => <Route path={r.path} component={r.component} exact={r.exact} />)};
							</Switch>
						</ScrollToTop>
					</div>


				</div>

			</div>
		</>


	}
};


const mapStateToProps = (state) => {
	return {
		login: state.admin.login,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getLogin: () => {
		dispatch(adminActions.getLogin());
	},


});

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(App);