import * as URL from '../../utils/url';
import {
  apiURL
} from '../vars';
import axios from 'axios';

export const RESET_ITEM_REQUEST = 'RESET_ITEM_REQUEST';
export const resetItem = () => ({
  type: RESET_ITEM_REQUEST,
});



// New connection
export const NEW_ITEM_REQUEST = 'NEW_ITEM_REQUEST';
export const NEW_ITEM_RESPONSE = 'NEW_ITEM_RESPONSE';

const newItemRequest = (request, save = null) => ({
  type: NEW_ITEM_REQUEST,
  request: request,
  save: save
});


const newItemResponse = (response, save = null) => ({
  type: NEW_ITEM_RESPONSE,
  response: response,
  save: save
});

export const newItem = (category, request, save = null) => {
  return (dispatch) => {
    dispatch(newItemRequest(request, save));
    fetch('/data/' + category + '/new.json', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    }).then(function (response) {
      return response.json();
    }).then(function (json) {
      dispatch(newItemResponse(json, save));
    });
  };

};

// Get item
export const GET_ITEM_REQUEST = 'GET_ITEM_REQUEST';
export const GET_ITEM_RESPONSE = 'GET_ITEM_RESPONSE';

const getItemRequest = (request, save = null) => ({
  type: GET_ITEM_REQUEST,
  request: request,
  save: save
});


const getItemResponse = (response, save = null) => ({
  type: GET_ITEM_RESPONSE,
  response: response,
  save: save
});


// If save is set, it saves it to that key
export const getItem = (category, request, save = null, endpoint = "get") => {
  return (dispatch) => {
    dispatch(getItemRequest(request, save));
    var req = ``;
    var options = {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
    var urlParams = URL.AsParams(request)
    delete options.headers;
    req = `/data/${category}/${endpoint}.json?${urlParams}`

    return fetch(req, options).then(function (response) {
      return response.json();
    }).then(function (json) {
      dispatch(getItemResponse(json, save));
    });

  };
};



export const getItemSSR = (category, request) => {
  var options = {
    method: "GET",
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }

  var urlParams = URL.AsParams(request)
  var req = `${apiURL}${category}/get.json?${urlParams}`

  var ret = axios.get(req, options).then(response => {
    return response.data
  });
  return ret;
};

// Set
export const SET_ITEM_REQUEST = 'SET_ITEM_REQUEST';
export const SET_ITEM_RESPONSE = 'SET_ITEM_RESPONSE';

const setItemRequest = (request) => ({
  type: SET_ITEM_REQUEST,
  request: request
});


const setItemResponse = (response) => ({
  type: SET_ITEM_RESPONSE,
  response: response
});

export const setItem = (category, request, endpoint = "set") => {
  return (dispatch) => {
    dispatch(setItemRequest(request));
    fetch('/data/' + category + '/'+endpoint+'.json', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    }).then(function (response) {
      return response.json();
    }).then(function (json) {
      dispatch(setItemResponse(json));
    });
  };

};