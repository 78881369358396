import * as URL from '../../utils/url';

export const RESET_ADMIN_REQUEST = 'RESET_ADMIN_REQUEST';
export const resetAdmin = () => ({
  type: RESET_ADMIN_REQUEST,
});

// Login
export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_RESPONSE = 'GET_LOGIN_RESPONSE';

const getLoginRequest = (request) => ({
  type: GET_LOGIN_REQUEST,
  request: request
});


const getLoginResponse = (response) => ({
  type: GET_LOGIN_RESPONSE,
  response: response
});

export const getLogin = (request) => {
  return (dispatch) => {
    var req = ``;
    // TODO https://stackoverflow.com/questions/45352550/sending-passwords-over-https-get-vs-post
    // Should really be using headers to send password
    var options = {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
    var urlParams = URL.AsParams(request)
    delete options.headers;
    req = `/data/admins/login.json?${urlParams}`
    dispatch(getLoginRequest(request));

    return fetch(req, options).then(function (response) {
      return response.json();
    }).then(function (json) {
      dispatch(getLoginResponse(json));
    });

  };

}

// Logout
export const GET_LOGOUT_REQUEST = 'GET_LOGOUT_REQUEST';
export const GET_LOGOUT_RESPONSE = 'GET_LOGOUT_RESPONSE';

const getLogoutRequest = (request) => ({
  type: GET_LOGOUT_REQUEST,
  request: request
});


const getLogoutResponse = (response) => ({
  type: GET_LOGOUT_RESPONSE,
  response: response
});

export const getLogout = (request) => {
  return (dispatch) => {
    var req = ``;
    var options = {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
    var urlParams = URL.AsParams(request)
    delete options.headers;
    req = `/data/admins/logout.json?${urlParams}`
    dispatch(getLogoutRequest(request));

    return fetch(req, options).then(function (response) {
      return response.json();
    }).then(function (json) {
      dispatch(getLogoutResponse(json));
    });

  };

}

